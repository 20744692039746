<template>
  <footer class="app-width">
    <p>
      All trademarks are property of their respective owners. Google, YouTube, the Google Logo, the YouTube Logo, are all trademarks of Alphabet Inc., registered in the U.S. and other countries.
    </p>
    <router-link :to="{name: 'privacy'}">
      Privacy Policy
    </router-link>
  </footer>
</template>
<script lang="ts">
  import Vue from 'vue';

  export default Vue.extend({});

</script>
<style scoped lang="less">
  @import '../../less/variables';

  footer{
    padding: 1em 0;
    font-size:0.9rem;
  }
  p{
    color: @grayMedium;
  }
  @media(min-width: @firstbreakpoint){
    footer{
      padding: 2em 0;
    }
  }
  @media(min-width: @secondbreakpoint){
    footer{
      padding: 2em 0;
    }
  }
  @media(min-width: @thirdbreakpoint){
    footer{
      padding: 2em 0;
    }
  }
</style>
