<template>
  <router-link
      v-if="couponIsAvailable"
      :to="route"
      class="coupon-banner"
  >
    <div>
      {{ couponDescription }}
    </div>
    <span v-if="$store.state.coupon.countdown" class="countdown">
      Only {{ $store.state.coupon.countdown }} left.
    </span>
  </router-link>
</template>

<script>
import Vue from 'vue';
import { mapGetters } from 'vuex';

export default Vue.extend({
  computed: {
    ...mapGetters('coupon', ['couponIsAvailable', 'couponDescription', 'expires']),
    route() {
      return { name: 'pricing-with-coupon' };
    }
  },
  watch: {
    expires: {
      handler(newValue) {
        if (newValue) {
          this.$store.dispatch('coupon/startCountdown');
        } else {
          this.$store.dispatch('coupon/stopCountdown');
        }
      },
      immediate: true
    }
  }
});
</script>
<style lang="less" scoped>
  @import '@/less/variables.less';
  .coupon-banner{
    background: @purpleLight;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.5em 1em;
    gap: 1em;
    font-weight: 500;
    cursor: pointer;
    &:hover{
      background: @purpleVibrant;
      .countdown{
        background: @color6;
      }
    }
  }
  .countdown{
    background: @white;
    color: @purpleVibrant;
    font-weight: 700;
    display: inline-block;
    border-radius: 2em;
    padding: 0 0.75em;
    font-size: 0.9em;
    white-space: nowrap;

  }
</style>