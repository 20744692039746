<template>
  <main :class="classes">
    <TheAppHeader
      v-if="showHeader"
      ref="header"
      :show-menu="showMenu"
    />
    <div class="page-content">
      <slot />
    </div>
    <TheAppFooter v-if="showFooter" />
    <div
      v-else-if="showFalseFooter"
      class="false-footer"
    />
  </main>
</template>

<script lang="ts">
import TheAppHeader from "@/components/organisms/TheAppHeader.vue";
import TheAppFooter from "@/components/organisms/TheAppFooter.vue";


export default {
  components: {
    TheAppHeader,
    TheAppFooter
  },
  props: {
    showMenu: {
      type: Boolean,
      default: true
    },
    showFooter: {
      type: Boolean,
      default: true
    },
    showFalseFooter: {
      type: Boolean,
      default: true
    },
    showHeader: {
      type: Boolean,
      default: true
    },
    fixHeader: {
      type: Boolean,
      default: false
    }
  },
  data(){
    return {
      scrolled: false
    }
  },
  computed: {
    classes(){
      return {
        'app-page': true,
        'fix-header': this.fixHeader,
        scrolled: this.scrolled
      }
    }
  },
  created(){
    window.addEventListener('scroll', this.handleScroll, {passive: true});
  },
  methods: {
    handleScroll(){
      this.scrolled = window.scrollY > 10;
    }
  }
}
</script>

<style scoped lang="less">
@import '../../less/variables';
@import '../../less/mixins';
  .app-page {
    width: 100vw;
    min-height: 100vh;
    background-size: cover;
    background-position: top center;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    .app-header{

      background: white;
    }
    &.fix-header {
      header.app-header{
        position: fixed;
        width: auto;
        top:0;
        left: 0;
        right: 0;
        padding: 0;
        z-index: 2;
        transition: box-shadow 500ms;
      }
      &.scrolled header.app-header{
        //box-shadow: 0 0 1em rgba(0,0,0,0.5);
      }
    }
  }
  .page-content{
    flex-grow: 1;
    .content-top{
      align-self: flex-start;
    }
  }
  &.centered .page-content{
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .false-footer{
    height: 4em;
  }

</style>
