<template>
  <header class="app-header">
    <CouponBanner />
    <div
      class="app-width"
      style="justify-content: space-between"
    >
      <div class="logo-container">
        <router-link
          v-if="false"
          class="logo"
          :to="{name: 'Home'}"
        >
          <img
            src="/img/youchat-logo.png"
            class="logo"
          >
          <span>
            YouChat<br>
            <span class="beta">
              By <a href="https://ChatWithVideos.com">ChatWithVideos.com</a>
            </span>
          </span>
        </router-link>
      </div>
      <TheAppMenu
        v-if="showMenu"
        id="site-menu"
      />
    </div>
  </header>
</template>
<script lang="ts">
  import Vue from 'vue';
  import TheAppMenu from "@/components/molecules/TheAppMenu.vue";
  import CouponBanner from "@/components/molecules/CouponBanner.vue";

  export default Vue.extend({
    components: {
      CouponBanner,
      TheAppMenu
    },
    props: {
      showMenu: {
        type: Boolean,
        default: true
      }
    }
  });
</script>
<style scoped lang="less">
  @import '../../less/variables';

  .max-width .app-width{
    width: 100% !important;
  }

  .app-width{
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    min-height: 66px;
  }

  .logo{
    font-weight: 700;
    font-size: 1.5rem;
    color: @black;
    display: flex;
    align-items: flex-start;
    gap: 0.5em;
    line-height: 1em;
    img {
      width: 42px;
      vertical-align: middle;
      position: relative;
      top: -2px;
    }
    span.beta{
      font-size: 0.6em;
      font-weight: 400;
      opacity: 0.5;
    }
    a{
      color: inherit;
    }
  }


  @media(min-width: @zeroethbreakpoint){
    .logo img{
      width: 35px;
    }
  }
  @media(min-width: @secondbreakpoint){
    header{
      justify-content: stretch;
    }
  }
  @media(min-width: @thirdbreakpoint){
    #site-menu{
      flex-grow: 1;
    }
    .app-width{
      min-height: 100px;
    }
  }
</style>
